// Imports
@import "mixins/mixins";
@import "commom/imports";
// @import "commom/keyframes";
@import "commom/variables";
@import "commom/fonts";
@import "commom/default";
@import "commom/padroes";
@import "commom/loader";

//Components
@import "components/buttons";
@import "components/inputs";
@import "components/h1";
@import "components/h2";

//Layout Site
@import "header";

@import "agendamento";
@import "vitrine";
@import "psicoterapia";
@import "perfil";
@import "servicos";
@import "servico";
@import "profissionais";
@import "fotos";
@import "contato";

@import "footer";