.profissionais {
	display: block;
	padding: 85px 0 120px;
	background-color: #a88184;
	margin-bottom: -120px;
	h1 {
		color: #ffffff;
	}
	.chamada {
		color: #fff;
		font-size: 22px;
		line-height: 34px;
		font-family: $ffMontSerrat;
		font-weight: 500;
		text-align: center;
		margin-bottom: 60px;
	}
	.profissional {
		margin-bottom: 40px;
		.foto {
			position: relative;
			margin-bottom: 25px;
			&::before {
				position: absolute;
				content: "";
				background-color: $bordo;
				width: 195px;
				height: 195px;
				z-index: 1;
				border-radius: 50%;
				left: 24px;
				top: -8px;
				@include breakpoint(768px, max) {
					left: 50%;
					transform: translateX(-50%);
				}
			}
			figure {
				position: relative;
				z-index: 2;
				width: 170px;
				height: 170px;
				overflow: hidden;
				margin: auto;
				img {
					width: 100%;
					left: 50%;
					position: relative;
					transform: translateX(-50%);
					border-radius: 50%;
					max-height: 170px;
				}
			}
		}
		h2 {
			color: #ffffff;
			font-family: $ffMontSerrat;
			font-size: 22px;
			line-height: 28px;
			text-transform: none;
			text-align: center;
			max-width: 190px;
			margin: auto auto 10px;
		}
		.crp,
		.telefone {
			color: #ffffff;
			font-family: $ffMontSerrat;
			font-size: 14px;
			line-height: 18px;
			text-transform: none;
			text-align: center;
		}
	}
}
