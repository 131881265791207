.servico {
    display: block;
    margin: 85px 0;
    .nome {
        color: #3E4C5B;
        text-transform: uppercase;
        font-family: $ffMontSerrat;
        font-size: 14px;
        line-height: 18px;
        font-weight: 600;
        margin-bottom: 20px;
    }
    h1 {
        text-align: left;
    }
    .texto {
        color: #9AA8B6;
        font-size: 22px;
        line-height: 34px;
        font-family: $ffMontSerrat;
        font-weight: 500;
        p {
            margin-bottom: 30px;
        }
    }
}