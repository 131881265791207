header {
	/* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#fefefe+0,f7f6f2+100 */
	background: rgb(254,254,254); /* Old browsers */
	background: -moz-radial-gradient(center, ellipse cover, rgba(254,254,254,1) 0%, rgba(247,246,242,1) 100%); /* FF3.6-15 */
	background: -webkit-radial-gradient(center, ellipse cover, rgba(254,254,254,1) 0%,rgba(247,246,242,1) 100%); /* Chrome10-25,Safari5.1-6 */
	background: radial-gradient(ellipse at center, rgba(254,254,254,1) 0%,rgba(247,246,242,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#fefefe', endColorstr='#f7f6f2',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
	padding: 40px 0 30px;

	@include breakpoint(768px, max) {
		position: fixed;
		z-index: 9;
		width: 100%;
		top: 0;
		padding: 20px 0;
	}

	.titulo {
		text-align: right;
		@include breakpoint(xs) {
			display: none;
		}
		h1 {
			font-family: $ffRaleway;
			font-size: 40px;
			font-weight: 900;
			font-style: italic;
			color: $bordo;
			line-height: 48px;
			width: auto;
			text-align: right;
			margin-bottom: 0;
		}
		h2 {
			font-family: $ffRaleway;
			font-size: 30px;
			font-weight: 400;
			color: $bordo;
			line-height: 35px;
		}
	}

	.menu {
		margin-top: 24px;
		.botaoResponsivo {
			position: relative;
			width: auto;
			min-width: 150px;
			height: 60px;
			right: 0px;
			display: none;
			margin-top: 0;
			@include breakpoint("768px", "max") {
				display: block;
				min-width: initial;
				width: 47px;
				height: 36px;
				margin-top: 15px;
				position: absolute;
				right: 20px;
				top: 160px;
				z-index: 3;
			}
			@include breakpoint("480px", "max") {
				top: 0;
			}
			@include breakpoint("380px", "max") {
				top: 0;
			}
			@include breakpoint("320px", "max") {
				top: 0;
			}
			.linhasmr {
				float: right;
				margin-top: 8px;
				.b1 {
					top: 0px;
				}
				.b2 {
					top: 10px;
				}
				.b3 {
					top: 20px;
				}
				> span {
					background-color: $bordo;
					height: 5px;
					right: 0;
					position: absolute;
					top: 0;
					transition: all 0.3s ease 0s;
					width: 40px;
				}
			}
			.ativo {
				.b1 {
					transform: rotate(-45deg);
					-webkit-transform: rotate(-45deg);
					top: 21px;
					right: 0;
					@include breakpoint("768px", "max") {
						top: 14px;
					}
				}
				.b2 {
					width: 0px;
				}
				.b3 {
					transform: rotate(45deg);
					-webkit-transform: rotate(45deg);
					top: 21px;
					right: 0;
					@include breakpoint("768px", "max") {
						top: 14px;
					}
				}
			}
			&:hover {
				cursor: pointer;
				text-decoration: none;
			}
		}
		nav {
			transition: all 0.4s ease;
			@include breakpoint("768px", "max") {
				display: block;
				overflow: auto;
				position: fixed;
				width: 100vw;
				left: -1000px;
				top: 100px;
				height: calc(100vh - 100px);
				background-color: $bordo;
				z-index: 9;
			}
			ul {
				position: relative;
				margin: 0;
				text-align: right;
				@include breakpoint("768px", "max") {
					padding: 30px 0;
				}
				li {
					position: relative;
					list-style-type: none;
					display: inline-block;
					vertical-align: middle;
					transition: all 0.3s ease;
					padding: 0 20px;
					@include breakpoint("768px", "max") {
						display: block;
						padding: 0 30px;
					}
					a {
						font-family: $ffRaleway;
						position: relative;
						text-align: center;
						font-size: 16px;
						padding: 0;
						display: block;
						font-weight: 600;
						text-transform: uppercase;
						color: $cinza;
						transition: all .3s ease;
						@include breakpoint("768px", "max") {
							display: block;
							color: #ffffff;
							font-size: 28px;
							line-height: 38px;
							text-align: left;
							margin-bottom: 15px;
						}
						&::before {
							position: absolute;
							z-index: 1;
							content: '';
							width: 30px;
							height: 2px;
							background-color: $cinza;
							transition: all .3s ease;
							bottom: -5px;
							left: 50%;
							transform: translateX(-50%);
							@include breakpoint("768px", "max") {
								left: 0;
								transform: none;
							}
						}
						&:hover {
							color: $bordo;
							text-decoration: none;
							@include breakpoint("768px", "max") {
								color: #ffffff;
							}
							&::before {
								background-color: $bordo;
								width: 100%;
								@include breakpoint("768px", "max") {
									background-color: #ffffff;
								}
							}
						}
						&.noMargin {
							margin-right: 0;
						}
					}
					&:last-child {
						padding-right: 0;
					}
					&.ativo {
						a {
							color: $bordo;
							&::before {
								background-color: $bordo;
								width: 100%;
							}
						}
					}
				}
			}
			&.ativo {
				left: 0;
			}
		}
	}
}
