h2 {
    display: block;
    width: 100%;
    color: #A88184;
    font-family: $ffMontSerrat;
    font-size: 20px;
    line-height: 24px;
    font-weight: 600;
    padding: 0;
    margin: 0 0 20px;
    text-transform: uppercase;
    @include breakpoint(768px, max) {
        font-size: 25px;
        line-height: 30px;
    }
}