h1 {
    display: block;
    width: 100%;
    color: $bordo;
    font-family: $ffMontSerrat;
    font-size: 50px;
    line-height: 60px;
    font-weight: 600;
    padding: 0;
    margin: 0 0 30px;
    text-align: center;
    @include breakpoint(768px, max) {
        font-size: 25px;
        line-height: 30px;
    }
}