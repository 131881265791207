.servicos {
    display: block;
    margin: 85px 0;
    .chamada {
        color: #9AA8B6;
        font-size: 22px;
        line-height: 34px;
        font-family: $ffMontSerrat;
        font-weight: 500;
        margin-bottom: 50px;
        text-align: center;
    }
    .listagem {
        a {
            display: block;
            background-image: url($img + 'servicos/icone.png');
            background-repeat: no-repeat;
            background-position: left top;
            min-height: 28px;
            padding-left: 42px;
            font-family: $ffMontSerrat;
            font-size: 22px;
            font-weight: 600;
            line-height: 28px;
            color: #000000;
            margin-bottom: 30px;
            &:hover {
                text-decoration: none;
                color: $bordo;
            }
        }
    }
}