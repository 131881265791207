* {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
}

body {
    font: lineHeight(14px, 120%) $ffMontSerrat;
    overflow-x: hidden !important;
    padding: 0;
    margin: 0;
    width: 100vw;
    height: 100%;
    background-color: #FFF;
    &.blockScroll {
        overflow: hidden !important;
    }
    @include breakpoint(768px, max) {
        padding-top: 50px;
    }
}