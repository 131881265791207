.contato {
    display: block;
    margin: 85px 0;
    .chamada {
        color: #9AA8B6;
        font-size: 22px;
        line-height: 34px;
        font-family: $ffMontSerrat;
        font-weight: 500;
        text-align: center;
        margin-bottom: 40px;
    }
    .dados {
        address, p {
            font-family: $ffMontSerrat;
            font-size: 18px;
            line-height: 34px;
            font-weight: 500;
            color: #9AA8B6;
        }
        .telefones {
            font-family: $ffMontSerrat;
            font-size: 20px;
            line-height: 34px;
            font-weight: 500;
            color: $bordo;
        }
    }
    .mapa {
        margin-bottom: 30px;
    }
    .redes {
        nav {
            ul {
                li {
                    list-style-type: none;
                    display: inline-block;
                    a {
                        display: table;
                        margin-right: 8px;
                        color: #666666;
                        i {
                            font-size: 30px;
                        }
                        &:hover {
                            color: $bordo;
                        }
                    }
                }
            }
        }
    }
}