.fotos {
    display: block;
    margin: 85px 0;
    .chamada {
        color: #9AA8B6;
        font-size: 22px;
        line-height: 34px;
        font-family: $ffMontSerrat;
        font-weight: 500;
        margin-bottom: 30px;
    }
    a {
        display: block;
        margin-bottom: 24px;
    }
}