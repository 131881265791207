.agendamento {
    background-image: url($img + 'agendamento/background.png');
    background-repeat: no-repeat;
    background-position: center top;
    padding: 150px 0 80px;
    h2 {
        color: #ffffff;
        font-size: 24px;
        font-family: $ffMontSerrat;
        line-height: 30px;
        font-weight: 500;
        text-transform: uppercase;
        margin: 0 0 20px;
        padding: 0;
    }
    a {
        display: block;
        font-family: $ffMontSerrat;
        font-size: 36px;
        line-height: 45px;
        color: #ffffff;
        text-align: center;
        margin: 15px 0;
        font-weight: 700;
    }
    .texto {
        display: block;
        font-family: $ffMontSerrat;
        font-size: 24px;
        line-height: 30px;
        color: #ffffff;
        text-align: center;
        margin: 15px 0;
        font-weight: 500;
    }
    .divisor {
        display: table;
        width: 1px;
        height: 330px;
        background-color: #979797;
        margin: auto;
    }
}
