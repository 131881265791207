.psicoterapia {
    display: block;
    margin: 85px 0;
    h1 {
        text-align: center;
        margin-bottom: 30px;
    }
    .texto {
        color: #9AA8B6;
        font-size: 22px;
        line-height: 34px;
        font-family: $ffMontSerrat;
        font-weight: 500;
        p {
            text-align: center;
            margin-bottom: 40px;
        }
    }
}