.form-group {
    margin-bottom: 5px;
}

.form-control {
    font-family: $ffMontSerrat;
    border-radius: 0;
    background-color: #fff;
    border: #A88184 1px solid;
    box-shadow: none;
    font-size: 18px;
    line-height: 22px;
    color: #9AA8B6;
    font-weight: 300;
    padding: 10px 24px;
    height: 52px;
    &::-webkit-input-placeholder {
        /* Chrome/Opera/Safari */
        color: #9AA8B6;
        font-size: 18px;
        line-height: normal;
    }
    &::-moz-placeholder {
        /* Firefox 19+ */
        color: #9AA8B6;
        font-size: 18px;
        line-height: normal;
    }
    &:-ms-input-placeholder {
        /* IE 10+ */
        color: #9AA8B6;
        font-size: 18px;
        line-height: normal;
    }
    &:-moz-placeholder {
        /* Firefox 18- */
        color: #9AA8B6;
        font-size: 18px;
        line-height: normal;
    }
}
