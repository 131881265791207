@mixin breakpoint($breakpoint, $break: null) {
  @if $break != null {
    $mediaVal : $breakpoint;

    @each $type, $value in $breakpoints {
      @if map-has-key($value, $breakpoint) {
        $mediaVal : map-get($value, $breakpoint);
      }
    }

    @media screen and (#{$break}-width: #{$mediaVal}) {
      @content;
    }
  } @else {
    @each $type, $value in $breakpoints {
      @if map-has-key($value, $breakpoint) {
        @media screen and (#{$type}-width: #{map-get($value, $breakpoint)}) {
          @content;
        }
      }
    }
  }
}

@function lineHeight($fontSize, $lineHeight) {
  @return #{$fontSize}/#{$lineHeight};
}

@mixin redGradiente() {
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#7a2230+0,cc2c45+100 */
  background: rgb(122,34,48); /* Old browsers */
  background: -moz-linear-gradient(top, rgba(122,34,48,1) 0%, rgba(204,44,69,1) 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(top, rgba(122,34,48,1) 0%,rgba(204,44,69,1) 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, rgba(122,34,48,1) 0%,rgba(204,44,69,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#7a2230', endColorstr='#cc2c45',GradientType=0 ); /* IE6-9 */
}
@mixin redGradienteHover() {
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#7a2230+0,cc2c45+100 */
  background: rgb(122,34,48); /* Old browsers */
  background: -moz-linear-gradient(top, rgba(204,44,69,1) 0%, rgba(122,34,48,1) 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(top, gba(204,44,69,1) 0%,rrgba(122,34,48,1) 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, gba(204,44,69,1) 0%,rrgba(122,34,48,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#7a2230', endColorstr='#cc2c45',GradientType=0 ); /* IE6-9 */
}
@mixin greyGradiente() {
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#ffffff+0,e5e5e5+100 */
  background: rgb(229,229,229); /* Old browsers */
  background: -moz-linear-gradient(top, rgba(229,229,229,1) 0%, rgba(255,255,255,1) 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(top, rgba(229,229,229,1) 0%,rgba(255,255,255,1) 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, rgba(229,229,229,1) 0%,rgba(255,255,255,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#e5e5e5', endColorstr='#ffffff',GradientType=0 ); /* IE6-9 */
}
@mixin greyGradienteInverse() {
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#ffffff+0,e5e5e5+100 */
  background: rgb(255,255,255); /* Old browsers */
  background: -moz-linear-gradient(top, rgba(255,255,255,1) 0%, rgba(229,229,229,1) 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(top, rgba(255,255,255,1) 0%,rgba(229,229,229,1) 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, rgba(255,255,255,1) 0%,rgba(229,229,229,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#e5e5e5',GradientType=0 ); /* IE6-9 */
}