/*
  SASS Variables for project
 */
$version: "v1.0.0";
$author: "Alexander Moraes";

// COLORS DEFAULT
$valid: #2ecc71;
$error: #FF0000;

$cinza: #808285;
$preto: #000;
$amarelo: #fff200;
$azul: #10243B;

$bordo: #5B0627;


//Tamanho Container
$containerWidth: 1060px;

//Font Padrão do Site

$ffRaleway: 'Raleway', sans-serif;;
$ffMontSerrat: 'Montserrat', sans-serif;;

$corBarraResponsivo: #5B0627;

// PATHS
$fontsSRC: "../fonts/";
$img: "../imgs/";

$fancybox-image-url: '../imgs/fancybox/';


// BREAKPOINTS
$breakpoints: (max: (xs: 767px), min: (sm: 768px, md: 992px, lg: 1200px));