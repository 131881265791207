.btn {
    &.btn-site {
        position: relative;
        font-family: $ffMontSerrat;
        background-color: #A88184;
        border-radius: 0;
        font-size: 18px;
        line-height: 22px;
        font-weight: 300;
        color: #FFF;
        padding: 12px 30px;
        text-align: center;
        text-transform: uppercase;
        &:hover {
            background-color: $bordo;
            color: #FFF;
            cursor: pointer;
        }
    }
}
