.vitrine {
    display: block;
    width: 100%;
    margin: auto auto 0;
    height : 600px;
    background-color: #fff;
    @include breakpoint('768px', 'max') {
        margin-bottom: 40px;
        height: 200px;
    }
    .owl-carousel {
        height : 600px;
        max-width: 100%;
        margin: auto;
        display: block;
        @include breakpoint('768px', 'max') {
            height: 200px;
        }
        .item {
            height: 600px;
            background-repeat : no-repeat;
            background-position: center center;
            background-size: cover;
            position: relative;
            @include breakpoint('768px', 'max') {
                height: 200px;
            }
            .conteudo {
                position: absolute;
                width: 100%;
                max-width: 1170px;
                left: 50%;
                transform: translateX(-50%);
                bottom: 140px;
                color: $bordo;
                font-size: 34px;
                line-height: 45px;
                font-family: $ffMontSerrat;
                .texto {
                    max-width: 510px;
                }
            }
        }
        .owl-nav {
            width: 100%;
            div {
                position: absolute;
                display: block;
                width: 20px;
                height: 34px;
                z-index: 9999999;
                top: 50%;
                margin-top: -14px;
                text-indent: 1000%;
                white-space: nowrap;
                overflow: hidden;
            }
            .owl-prev {
                left: 30px;
                background: transparent url($img + 'vitrine/seta-left.png') no-repeat 0 0;
                @include breakpoint(768px, max) {
                    left: 30px;
                }
            }
            .owl-next {
                right: 30px;
                background: transparent url($img + 'vitrine/seta-right.png') no-repeat 0 0;
                @include breakpoint(768px, max) {
                    right: 30px;
                }
            }
        }
    }
    // @include breakpoint('768px', 'max'){
    //     .owl-carousel {
    //         .item {
    //             min-height: auto;
    //             height: calc(75vh - 50px);
    //             padding: 0 20px;
    //         }
    //     }
    // }
}