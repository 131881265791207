footer {
    background-color: $bordo;
    padding: 32px 0;
    color: #ffffff;
    font-family: $ffMontSerrat;
    font-size: 12px;
    line-height: 16px;
    a {
        color: #ffffff;
        font-family: $ffMontSerrat;
        font-size: 12px;
        line-height: 16px;
        &:hover {
            color: #ffffff;
            text-decoration: underline;
        }
    }
}