.perfil {
    display: block;
    margin: 85px 0;
    .nome {
        color: #3E4C5B;
        text-transform: uppercase;
        font-family: $ffMontSerrat;
        font-size: 14px;
        line-height: 18px;
        font-weight: 600;
        margin-bottom: 20px;
        @include breakpoint(768px, max) {
            margin-top: 20px;
        }
    }
    h1 {
        text-align: left;
        font-size: 46px;
        line-height: 50px;
        margin-bottom: 0;
    }
    .crp {
        color: #3E4C5B;
        font-size: 34px;
        line-height: 60px;
        margin-bottom: 30px;
    }
    .texto {
        color: #9AA8B6;
        font-size: 22px;
        line-height: 34px;
        font-family: $ffMontSerrat;
        font-weight: 500;
        p {
            margin-bottom: 30px;
        }
    }
}